export default {
  login: "login",
  WelcomeAgain: "welcome again",
  fogetPassword: "Forgot Password ?",
  DontHave: "Don't have account ?",
  createAccount: "Create account",
  LoginSocial: "Or Continue With",
  loginNav: "login",
  welcome: "welcome",
  logout: "Logout",
  reset: "reset",
  yourEmail: "Enter your email",
  thankYou: "Thank you",
  invalidEmail: "Invalid Email",
  resetPassword: "Reset Password",
  log1: "You are about to get your order !",
  log2: "Do you want to have a registered account with Humhumm?",
  logBtn1: "Yes, i want to register",
  logBtn2: "No, Continue as a guest",
  actionComplete: "Complete Action",
  retailBuyer: "Consumer",
  wholeSaleBuyer: "WholeSale Buyer",
  wholeSaleClients: "WholeSale Clients",
  characters: "Characters",
  specialCharacter: "Special Character",
  uppercaseLetter: "Uppercase Letter",
  lowercaseLetter: "Lowercase Letter",
  number: "Number",
  first_name: "First Name",
  last_name: "Last Name",
  workplace: "workplace",
  nationality: "nationality",
  residency: "residency",
  specialty: "specialty",
  sub_specialty: "sub_specialty",
  other: "other",
  other_sub_specialty: "other sub specialty",
  doctorRegister: "doctor registration",
  currentPosition: "current Position",
  Doctor: "Doctor",
  visitWebsite: "visit websites",
  "resend":"Resend"
};
