<template>
  <div class="subscripe">
    <div class="description text-center mb-5" v-if="
      (monthlyPlans && monthlyPlans.length) ||
      (yearlyPlans && yearlyPlans.length) ||
      freePlan
    ">
      <h6>{{ $t("profile.Pricing") }}</h6>
      <h3>{{ $t("profile.PricingPlans") }}</h3>
    </div>
    <div class="tabs d-flex justify-content-center align-items-center gap-2 mb-5">
      <h3 class="monthly cursor-pointer plan-text" :class="{ 'active-tab': activeTab == 'monthly' }"
        v-if="monthlyPlans && monthlyPlans.length" @click="
          activeTab = 'monthly';
        subscription_id = null;
        " id="monythly-tab">
        {{ $t("profile.monthly") }}
      </h3>
      <h3 class="yearly cursor-pointer plan-text" :class="{ 'active-tab': activeTab == 'yearly' }"
        v-if="yearlyPlans && yearlyPlans.length" @click="
          activeTab = 'yearly';
        subscription_id = null;
        " id="yearty-tab">
        {{ $t("profile.yearly") }}
      </h3>
      <h3 class="free cursor-pointer plan-text" :class="{ 'active-tab': activeTab == 'free' }"
        v-if="freePlan && !buyerUserData.trial_end_date && freePlan.status == 1" @click="
          activeTab = 'free';
        subscription_id = null;
        " id="free-tab">
        {{ $t("profile.freePlan") }}
      </h3>
    </div>
    <div class="plans" v-if="
      (monthlyPlans && monthlyPlans.length) ||
      (yearlyPlans && yearlyPlans.length) ||
      freePlan
    ">
      <div class="monthly-plans" v-if="activeTab == 'monthly' && monthlyPlans.length">
        <div id="price">
          <!--price tab-->
          <div class="row justify-content-center">
            <div class="plan col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-5" v-for="(plan, index) in monthlyPlans"
              :key="index" @click="selectPlan(plan)" :class="{ 'active-plan': subscription_id == plan.id }">
              <div class="plan-inner">
                <div class="entry-title">
                  <h3 v-if="plan.name_en || plan.name_ar" class="plan-top-name">
                    {{ $i18n.locale == "en" ? plan.name_en : plan.name_ar }}
                  </h3>
                  <div class="price">
                    <p>{{ plan.cost ? plan.cost : "0" }}</p>
                    <p class="m-0" v-if="$i18n.locale == 'en'">USD</p>
                    <p class="m-0" v-else>دولار</p>
                  </div>
                </div>
                <div class="d-flex w-100 h-100 flex-column justify-content-between align-items-center">
                  <div class="entry-content">
                    <ul>
                      <li>
                        <span v-if="
                          $i18n.locale == 'en' && plan && plan.description_en
                        " v-html="plan.description_en"></span>
                        <span v-if="
                          $i18n.locale == 'ar' && plan && plan.description_ar
                        " v-html="plan.description_ar"></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- <div class="btn-holder pb-3">
                  <div class="cursor-pointer" @click="selectPlan(plan)">
                    <font-awesome-icon
                      icon="fa-regular fa-circle-check"
                      size="2x"
                      :class="{ 'active-plan': subscription_id == plan.id }"
                    />
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="yearly-plans" v-if="activeTab == 'yearly' && yearlyPlans.length">
        <div id="price">
          <!--price tab-->
          <div class="row justify-content-center">
            <div class="plan col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-5" v-for="(plan, index) in yearlyPlans"
              :key="index" @click="selectPlan(plan)" :class="{ 'active-plan': subscription_id == plan.id }">
              <div class="plan-inner">
                <div class="entry-title">
                  <h3 v-if="plan.name_en || plan.name_ar" class="plan-top-name">
                    {{ $i18n.locale == "en" ? plan.name_en : plan.name_ar }}
                  </h3>
                  <div class="price">
                    <p>{{ plan.cost ? plan.cost : "0" }}</p>
                    <p class="m-0" v-if="$i18n.locale == 'en'">USD</p>
                    <p class="m-0" v-else>دولار</p>
                  </div>
                </div>
                <div class="d-flex w-100 h-100 flex-column justify-content-between align-items-center">
                  <div class="entry-content">
                    <ul>
                      <li>
                        <span v-if="
                          $i18n.locale == 'en' && plan && plan.description_en
                        " v-html="plan.description_en"></span>
                        <span v-if="$i18n.locale == 'ar' && plan.description_ar" v-html="plan.description_ar"></span>
                      </li>
                    </ul>
                  </div>
                  <!-- <div class="btn-holder pb-3">
                    <div class="cursor-pointer" @click="selectPlan(plan)">
                      <font-awesome-icon
                        icon="fa-regular fa-circle-check"
                        size="2x"
                        :class="{ 'active-plan': subscription_id == plan.id }"
                      />
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="free-plans" v-if="activeTab == 'free' && freePlan">
        <div class="text-center">
          <!--price tab-->
          <h3 v-if="freePlan.type">
            {{ $t(`${$t("profile.freePeripodMessage")} ${freePlan.type}`) }}
          </h3>
        </div>
      </div>
      <div class="action d-flex justify-content-center" v-if="
        monthlyPlans.length ||
        yearlyPlans.length ||
        (freePlan && activeTab !== 'free')
      ">
        <div class="p-5">
          <div class="d-flex justify-content-end">
            <button class="px-4 btn-block py-2 border-main" @click="confirmPlan" :disabled="!subscription_id"
              v-if="activeTab !== 'free'">
              {{ $t("confirm") }}
            </button>
          </div>
        </div>
      </div>
      <div class="action d-flex justify-content-center" v-if="
        activeTab == 'free' &&
        !buyerUserData.trial_end_date &&
        freePlan &&
        freePlan.status == 1
      ">
        <div class="p-5">
          <div class="d-flex justify-content-end">
            <button class="px-4 btn-block py-2 border-main" @click="startFreeTrial">
              {{ $t("profile.startFreeTrial") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 text-center" v-else>
      <h3>
        {{ $t("profile.noPlans") }}
      </h3>
    </div>

    <!-- payment modal  -->
    <b-modal centered id="bv-modal-example" hide-footer>
      <template #modal-title>
        <h3>{{ $t("payment.paymentData") }}</h3>
      </template>
      <div class="d-block text-center">
        <div class="payment-method">
          <div class="methods-data">
            <div class="methods">
              <div class="method" v-if="bank_transfer_payment">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="paymentMethod1" name="paymentMethod" class="custom-control-input"
                    v-model="paymentFormData.payment_method" value="bank" />
                  <label class="custom-control-label" for="paymentMethod1">
                    {{ $t("payment.bankTransfer") }}
                  </label>
                  <span>{{ $t("payment.paymentByBank") }}</span>
                </div>
              </div>
              <div class="method d-flex justify-content-between align-content-center">
                <div class="custom-control custom-radio custom-control-inline" v-if="onlinePaymentGuestSettingControl">
                  <input type="radio" id="paymentMethod3" name="paymentMethod" class="custom-control-input"
                    v-model="paymentFormData.payment_method" value="visa" />
                  <label class="custom-control-label" for="paymentMethod3">
                    {{ $t("payment.onlinePayment") }}
                  </label>
                  <div class="online-media">
                    <img src="@/assets/images/cart.png" alt="" srcset="" />
                  </div>
                </div>
              </div>
              <!-- boubyan  -->
              <div class="method visa row justify-content-between align-content-center"
                v-if="boubyanPaymentGuestSettingControl">
                <div class="col-md-8 col-xs-12">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="paymentMethod4" name="paymentMethod" class="custom-control-input"
                      v-model="paymentFormData.payment_type" v-b-modal.boubyanModal value="boubyan" />
                    <label class="custom-control-label" for="paymentMethod4">
                      {{ $t("payment.BoubyanOnlinePayment") }}
                    </label>
                  </div>
                </div>
                <div class="col-md-4 col-xs-12">
                  <div class="online-media">
                    <img src="@/assets/images/cart.png" alt="" srcset="" />
                  </div>
                </div>
              </div>
              <!-- apple pay -->
              <!-- <ApplePayButton
                :totalAmount="'15.00'"
                :label="'Your Business Name'"
              /> -->
            </div>
            <div class="form-input mb-4 mt-3" v-if="paymentFormData.payment_method == 'bank'">
              <label for="bankImage">
                {{ $t("payment.uploadImage") }}
              </label>
              <b-form-group>
                <b-form-file size="lg" id="bankImage" @change="uploadBankImage"
                  :placeholder="$t('profile.filePlaceHolder')" drop-placeholder="Drop file here...">
                </b-form-file>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <b-button id="show-btn" class="btn btn-block" variant="outline-success" block @click.stop="confirmPaidPlan"
        :disabled="loading">
        <span>{{ $t("home.ok") }}</span>
        <span v-if="loading" class="mx-2">
          <b-spinner label="Spinning" small></b-spinner>
        </span>
      </b-button>
    </b-modal>
    <!-- this modal apper when bank payment method checked  -->
    <b-modal id="boubyanModal" :title="$t('payment.BoubyanOnlinePayment')">
      <div class="visa-form">
        <form @submit.prevent="submitForm">
          <div>
            <label for="card_number">{{ $t("payment.CardNumber") }}</label>
            <input type="text" id="card_number" v-model="card_number" placeholder="XXXX XXXX XXXX XXXX" maxlength="16"
              required />
          </div>

          <div>
            <label for="card_expiry_month">{{
              $t("payment.ExpiryMonth")
              }}</label>
            <input type="text" id="card_expiry_month" v-model="card_expiry_month" placeholder="MM" maxlength="2"
              required />
          </div>

          <div>
            <label for="card_expiry_year">{{ $t("payment.ExpiryYear") }}</label>
            <input type="text" id="card_expiry_year" v-model="card_expiry_year" placeholder="YYYY" maxlength="2"
              required />
          </div>

          <div>
            <label for="cvv">CVV</label>
            <input type="password" id="cvv" v-model="cvv" placeholder="XXX" maxlength="3" required />
          </div>
          <!-- <div class="d-flex justify-content-end">

           <button type="submit" class="btn border-main p-2 my-3">{{$t('payment.validate')}}</button>
         </div> -->
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import profile from "@/services/profile";
export default {
  async mounted() {
    // let subscribeDone = localStorage.getItem("subscribeDone");
    // if (subscribeDone == true || subscribeDone == "true") {
    //   localStorage.removeItem("subscribeDone");
    //   this.$router.push("/profile/account-information-b2b");
    // }
    await this.getPlans();
    await this.getFreePlans();
  },
  methods: {
    getPlans() {
      profile
        .subscribePlans()
        .then((res) => {
          // console.log(res);
          let myRes = null;
          let elementData = null;
          if (res?.data?.items?.length) {
            myRes = res.data.items;
            myRes.forEach((element) => {
              if (element?.data?.length) {
                elementData = element?.data;
                elementData.forEach((el) => {
                  if (el.type === "month") {
                    this.monthlyPlans.push(el);
                  } else {
                    this.yearlyPlans.push(el);
                  }
                });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setTimeout(() => {
            let yeartyTab = document.getElementById("yearty-tab");
            let monythlyTab = document.getElementById("monythly-tab");
            let freeTab = document.getElementById("free-tab");
            if (yeartyTab) {
              yeartyTab.classList.add("active-tab");
              this.activeTab = "yearly";
              yeartyTab.click();
              return;
            }
            if (monythlyTab) {
              yeartyTab.classList.add("active-tab");
              this.activeTab = "monthly";
              monythlyTab.click();
              return;
            }
            if (freeTab) {
              yeartyTab.classList.add("active-tab");
              this.activeTab = "yearly";
              freeTab.click();
              return;
            }
          }, 250);
        });
    },
    checkActiveTab() {
      if (
        !this.monthlyPlans?.length &&
        !this.yearlyPlans?.length &&
        this.freePlan
      ) {
        this.activeTab = "free";
      }
    },
    getFreePlans() {
      profile
        .getFreePlans()
        .then((res) => {
          this.freePlan = res.data.items.free_trial;
          this.checkActiveTab();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectPlan(plan) {
      this.subscription_id = plan.id;
      this.selectedPlan = plan;
    },
    confirmPlan() {
      if (this.selectedPlan.cost !== 0) {
        this.$bvModal.show("bv-modal-example");
      } else {
        this.confirmFreePlan();
      }
    },
    uploadBankImage(event) {
      this.paymentFormData.file = event.target.files[0];
    },
    confirmFreePlan() {
      this.loading = true;
      let payload = {
        subscription_id: this.subscription_id,
      };
      profile
        .confirmFreePlan(payload)
        .then(async (res) => {
          this.sucessMsg(res.data.message);
          await this.$store.dispatch("getUserInfo");
          await window.location.reload();
        })
        .catch((error) => {
          const err = Object.values(error)[2].data;
          this.errors = err.items;
          this.errMsg(err.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmPaidPlan() {
      this.loading = true;
      let formData = new FormData();

      // Prepare form data
      if (this.subscription_id) {
        formData.append("subscription_id", this.subscription_id);
      }
      if (this.paymentFormData?.payment_method) {
        formData.append("payment_method", this.paymentFormData.payment_method);
      }
      if (
        this.paymentFormData?.payment_method == "bank" &&
        this.paymentFormData?.file
      ) {
        formData.append("payment_image", this.paymentFormData.file);
      }

      if (this.paymentFormData?.payment_type == "boubyan") {
        this.paymentFormData.payment_method = "boubyan";
        formData.append("card_number", this.card_number);
        formData.append("card_expiry_month", this.card_expiry_month);
        formData.append("card_expiry_year", this.card_expiry_year);
        formData.append("cvv", this.cvv);
        formData.append("payment_method", "boubyan");
      }

      profile
        .confirmPaidPlan(formData)
        .then(async (res) => {
          this.sucessMsg(res.data.message);
          this.response_id = res.data.items.subscription.id;

          if (res?.data?.items?.url) {
            setTimeout(() => {
              window.location.href = res.data.items.url;
            }, 500);
          } else {
            this.$bvModal.hide("bv-modal-example");
            if (this.paymentFormData.payment_method == "boubyan") {
              this.getPlansRedirectUrl();
            } else {
              setTimeout(async () => {
                await this.updateUserData();
                this.$router.push(
                  {
                    path: "/",
                  },
                  () => {
                    window.location.reload();
                  }
                );
              }, 500);
            }
          }
        })
        .catch((error) => {
          const err = Object.values(error)[2].data;
          this.errors = err.items;
          this.errMsg(err.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    startFreeTrial() {
      profile
        .submitFreePlan()
        .then(async (res) => {
          this.sucessMsg(res.data.message);
          await this.$store.dispatch("getUserInfo");
          setTimeout(() => {
            this.$router.push(
              {
                path: "/profile/account-information-b2b",
              },

              () => {
                this.$store.dispatch("getUserInfo").then(() => {
                  location.reload();
                });
              }
            );
          }, 500);
          setTimeout(() => {
            this.$router.push("/");
          }, 750);
        })
        .catch((error) => {
          const err = Object.values(error)[2].data;
          this.errors = err.items;
          this.errMsg(err.message);
        });
    },
    getPlansRedirectUrl() {
      profile
        .getPlansRedirectUrl(this.response_id)
        .then((res) => {
          this.sucessMsg(res.data.message);
        })
        .then(async () => {
          await this.updateUserData();
          setTimeout(() => {
            localStorage.setItem("subscribeDone", true);
          }, 200);
          setTimeout(() => {
            this.$router.go("/");
          }, 250);
          // setTimeout( () => {
          //   this.$router.push(`/`).then(() => {
          //     window.location.reload();
          //   });
          // }, 500);
        })
        .catch((err) => {
          let errors = Object.values(err)[2].data;
          this.errors = errors.items;
          this.errMsg(err.message);
        });
    },
    async updateUserData() {
      await this.$store.dispatch("getUserInfo");
    },
  },
  data() {
    return {
      subscription_id: null,
      response_id: null,
      selectedPlan: null,
      activeTab: "monthly",
      monthlyPlans: [],
      yearlyPlans: [],
      freePlan: null,
      loading: false,
      paymentFormData: {
        file: null,
        payment_method: null,
      },
      errors: [],
      // boubyan visa modal data
      card_number: null,
      card_expiry_month: null,
      card_expiry_year: null,
      cvv: null,
    };
  },
  computed: {
    onlinePaymentGuestSettingControl() {
      return this.$store?.state?.guestSettings?.tab_payment_status;
    },
    boubyanPaymentGuestSettingControl() {
      return this.$store?.state?.guestSettings?.boubyan_payment_status;
    },
  },
};
</script>

<style lang="scss" scoped>
#price {
  text-align: center;
}

.plan {
  @media (max-width: 767px) {
    display: block;
    margin-bottom: 5rem;
  }
}

.plan-inner {
  cursor: pointer;
  margin: 0 auto;
  min-width: 280px;
  max-width: 100%;
  position: relative;
  box-shadow: 0px 0px 4px 1px var(--main-color);
  border-radius: 5px;
}

.entry-title {
  background: $main-color;
  height: 140px;
  position: relative;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.entry-title>h3 {
  background: $main-color;
  font-size: 20px;
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  color: #fff;
  border-bottom: 2px solid;
}

.entry-title .price {
  position: absolute;
  bottom: -25px;
  background: $main-color;
  height: 95px;
  width: 95px;
  margin: 0 auto;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: 50px;
  border: 5px solid #fff;
  line-height: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.price span {
  position: absolute;
  font-size: 9px;
  bottom: -10px;
  left: 30px;
  font-weight: 400;
}

.entry-content {
  color: #323232;
  height: 250px;
  overflow-y: auto;
  padding: 5px 10px;
}

.entry-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.entry-content li {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 0;
}

.entry-content li:last-child {
  border: none;
}

.btn {
  padding: 15px;
  text-align: center;
}

.btn a {
  background: #323232;
  padding: 10px 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

.ultimite .entry-title>h3 {
  background: #dd4b5e;
}

.ultimite .entry-title {
  background: #f75c70;
}

.ultimite .price {
  background: #dd4b5e;
}

.plan-text {
  border: 1px solid transparent;

  border-radius: 5px;
}

.active-tab {
  border-color: $main-color;
  color: $main-color;
}

.plan-text {
  padding: 10px 30px;
}

.payment-method {
  .methods-data {
    background: #ecf0f1;
    padding: 2rem;
    border-radius: 0.5rem;
    text-align: left;

    .info {
      border-bottom: 1px dashed #c5c6c6;
      padding: 1rem 0.3rem;
      color: #312620;
      font-weight: bold;
    }

    .total {
      padding: 1rem 0;
      color: #312620;
      font-weight: bold;

      .title {
        font-size: 14pt;
      }
    }

    .methods {
      background-color: #fff;
      border-radius: 0.5rem;
      border: 1px dashed #cfd0d0;

      .method {
        padding: 1rem;
        border-bottom: 1px dashed #cfd0d0;
        font-size: 11pt;
        color: #544842;

        .custom-radio {
          flex-wrap: wrap;
        }

        label {
          cursor: pointer;
        }

        span {
          width: 100%;
          font-size: 10pt;
          margin-top: -0.2rem;
          opacity: 0.7;
        }

        .online-media {
          img {
            object-fit: contain;
          }
        }
      }
    }
  }
}

.active-plan {
  .plan-inner {
    box-shadow: 0px 0px 4px 1px #1d2d53;
  }

  .entry-title,
  .entry-title,
  .price,
  .plan-top-name {
    background: #1d2d53;
  }
}

.visa-form {
  max-width: 400px;
  margin: 0 auto;
}

.visa-form label {
  display: block;
  margin-bottom: 4px;
  text-align: start;
}

.visa-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
}
</style>
