<template>
  <header :class="{ 'scrolled-nav': scrollPosition }" class="main-nav">
    <!-- navbar component  -->
    <div
      :class="{
        container: searchClicked == false,
        'container-fluid': searchClicked == true,
      }"
    >
      <nav :class="$i18n.locale" class="text-center">
        <!-- Main Header -->
        <!-- top nav  -->
        <div class="row justify-content-center align-items-center">
          <div
            class="col-lg-4 col-md-4 col-sm-6 d-flex justify-content-start align-items-center newMobileStyle"
          >
            <div
              class="lang-currency-country d-flex justify-content-between align-items-center"
            >
              <TopHeader />
            </div>
          </div>
          <div
            class="col-lg-4 col-md-4 col-sm-6 d-flex justify-content-center align-items-center newMobileStyle"
          >
            <div class="middle">
              <!-- logo is centered  -->
              <div class="d-flex">
                <div class="branding cursor-pointer" @click="goToHome()">
                  <img
                    :src="logoEnv"
                    v-if="logoEnv"
                    class="img-fluid storedLogo"
                    alt="site_logo"
                  />
                  <img
                    :src="siteLogo"
                    v-else-if="siteLogo"
                    class="img-fluid passedLogo"
                    alt="site_logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-0 col-sm-0">
            <div class="toggleMenu">
              <!-- Right Side have user login and search   -->
              <div class="right-side d-flex">
                <!-- Search Icon -->
                <div class="search-icon" v-if="!mobile">
                  <b-button
                    class="icon-search"
                    size="md"
                    v-if="searchClicked == true"
                    @click="closeSearch"
                  >
                    <font-awesome-icon icon="fa-solid fa-times" />
                  </b-button>
                  <span class="" v-if="searchClicked">
                    <div class="form-holder">
                      <b-form @submit.prevent="search" @keyup="search">
                        <b-form-input
                          :placeholder="$t('cart.search')"
                          class="search-input"
                          v-model="keyword"
                          ref="searchIcon"
                        ></b-form-input>
                        <div
                          class="floating-btn"
                          @click="searchBtn"
                          v-if="suggestionsExist == false && keyword.length"
                        >
                          <button>
                            <font-awesome-icon icon="fa-solid fa-arrow-right" />
                          </button>
                        </div>
                      </b-form>
                      <ul class="search-suggestions" v-if="suggestionsExist">
                        <span class="meaning-span">{{
                          $t("home.didMean")
                        }}</span>
                        <li
                          v-for="(suggest, index) in suggestions"
                          :key="index"
                          role="button"
                          @click="searchSuggestion(suggest)"
                        >
                          {{ suggest }}
                        </li>
                      </ul>
                    </div>
                  </span>
                </div>
                <div
                  class="d-flex justify-content-between align-items-center"
                  v-if="!mobile"
                >
                  <a
                    :href="`${supplierDomain}`"
                    target="_blank"
                    class="border-main color-main br-5 p-2 suppliers-link"
                    >{{ $t("home.suppliers") }}</a
                  >
                </div>
                <div
                  v-if="!mobile"
                  class="cart d-flex justify-content-between align-items-center"
                >
                  <span
                    class="cartLength cart-number"
                    v-if="cartItems && cartLength"
                  >
                    {{ cartLength }}
                  </span>
                  <span class="cart-icon">
                    <BIconCartDash width="25" height="25" />
                  </span>

                  <Cart class="cart-body"></Cart>
                </div>

                <div
                  v-if="!mobile && buyerUserData"
                  class="cart notify-holder d-flex justify-content-center align-items-center"
                >
                  <span class="cart-icon">
                    <BIconBell width="25" height="25" />
                  </span>
                  <span class="cartLength" v-if="notificationsLength > 0">
                    {{ notificationsLength > 0 ? notificationsLength : 0 }}
                  </span>
                  <Notify class="notify-body" :notifications="notifications" />
                </div>
                <!-- user sign in -->
                <div
                  class="login"
                  v-if="!mobile && !isLoggined && (b2b_status || b2c_status)"
                  v-b-toggle.login
                  ref="loginIcon"
                >
                  <div class="d-flex flex-column justify-content-center align-items-center">
                    <BIconPerson width="25" height="25" />
                    <h6 class="main-color">{{ $t("home.buyer") }}</h6>
                  </div>
                </div>
                <div v-if="!mobile && isLoggined">
                  <div class="user-profile">
                    <b-dropdown id="dropdown-1">
                      <template #button-content>
                        <span>
                          <BIconPerson width="25" height="25" />
                          <p v-if="buyerUserData.is_verified" class="d-flex align-items-center">
                            {{ $t("login.welcome") }} ,
                            <span v-if="buyerUserData.type === 'buyer'">
                              <span v-if="$i18n.locale == 'en'">{{
                                userInfo.item.company_name_en | shortName
                              }}</span>
                              <span
                                v-else-if="
                                  $i18n.locale == 'ar' &&
                                  userInfo.item.company_name_ar
                                "
                                >{{
                                  userInfo.item.company_name_ar | shortName
                                }}</span
                              >
                              <span v-else>{{
                                userInfo.item.company_name_en | shortName
                              }}</span>
                            </span>
                            <span v-else>
                              {{ userInfo.item.first_name | shortName }}
                            </span>
                            <span v-if="buyerUserData.buyer_type === 'Doctor'">{{ userInfo.item.first_name | shortName }}</span>
                          </p>
                          <p v-else>
                            {{ $t("login.welcome") }} ,
                            <span v-if="userInfo.item.type === 'buyer'">
                              <span v-if="$i18n.locale == 'en'">{{
                                userInfo.item.company_name_en | shortName
                              }}</span>
                              <span
                                v-else-if="
                                  $i18n.locale == 'ar' &&
                                  userInfo.item.company_name_ar
                                "
                                >{{
                                  userInfo.item.company_name_ar | shortName
                                }}</span
                              >
                              <span v-else>{{
                                userInfo.item.company_name_en | shortName
                              }}</span>
                            </span>
                            <span v-else>
                              {{ userInfo.item.first_name | shortName }}
                            </span>
                          </p>
                          
                        </span>
                      </template>
                      <b-dropdown-item
                        v-if="
                          userInfo.item.is_verified || buyerUserData.is_verified
                        "
                      >
                        <router-link to="/profile/categories">{{
                          $t("profile.myProfile")
                        }}</router-link>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <a class="logout" @click="logout()">
                          {{ $t("login.logout") }}
                        </a>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <!-- user Profile and name when login -->
                <Login v-if="!isLoggined" />
              </div>

              <!--Start Mbile Nav -->
              <div class="icon right-side" v-if="mobile">
                <div
                  class="row justify-content-center align-items-center search-icon"
                  v-if="mobile"
                ></div>

                <font-awesome-icon
                  v-b-toggle.sidebar-1
                  @click="toggleMobileNav"
                  icon="fa-solid fa-bars"
                  :class="{ 'icon-active': mobileNav }"
                  class="bar-icon"
                />
              </div>
              <transition name="mobile-nav">
                <b-sidebar
                  :right="getDir === 'rtl'"
                  v-if="mobileNav"
                  @hidden="closeSideBar"
                  id="sidebar-1"
                  backdrop
                  width="300px"
                  shadow
                  z-index="3"
                >
                  <MobileNav />
                </b-sidebar>
              </transition>
              <!--End Mbile Nav -->
            </div>
          </div>
        </div>

        <!--End Mbile Nav -->
      </nav>
    </div>
    <div class="botton-nav">
      <BottomHeader />
    </div>
  </header>
</template>

<script>
// nav component
import MobileNav from "./MobileNav.vue";
import Cart from "../cart/Cart.vue";
import Notify from "../notifications.vue";
import globalAxios from "@/services/global-axios";
import Login from "./login.vue";
import categories from "@/services/categories";
import TopHeader from "@/components/layouts/TopHeader";
import BottomHeader from "@/components/layouts/BottomHeader";

import { BIconBell, BIconCartDash, BIconPerson } from "bootstrap-vue";

export default {
  data() {
    return {
      scrollPosition: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      cartItemsLength: 0,
      cartWishlistLength: 0,
      wishlistItems: null,
      wishlistLength: 0,
      keyword: "",
      searchClicked: false,
      suggestionsExist: false,
      loadingPercent: 0,
      loadTime: 0,
      interval: null,
      myInterval: null,
    };
  },
  components: {
    Cart,
    MobileNav,
    Notify,
    Login,
    TopHeader,
    BottomHeader,
    BIconCartDash,
    BIconPerson,
    BIconBell,
  },
  created() {
    /**
     * @vuese
     * check Screen size
     */
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
    this.getCartProducts();
    if (this.buyerUserData) {
      this.getWishlistProducts();
    }

    let perfData = window.performance.timing;
    let estimatedTime = Math.abs(
      perfData.loadEventEnd - perfData.navigationStart
    );
    this.loadTime = parseInt((estimatedTime / 1000) % 60) * 100;
    this.doProgress();
  },
  methods: {
    doProgress() {
      let step = this.loadTime / 100;
      this.interval = setInterval(() => {
        this.loadingPercent++;
      }, step);
    },
    /**
     * @vuese
     * close SideBar function
     */
    closeSideBar() {
      this.mobileNav = null;
    },
    /**
     * @vuese
     * toggle Mobile Nav function
     */
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    /**
     * @vuese
     * check Screen function
     */
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1199) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    /**
     * @vuese
     * go To Home function
     */
    goToHome() {
      this.$router.push("/");
    },
    /**
     * @vuese
     * get Cart Products function
     */
    getCartProducts() {
      return globalAxios.post("/cart").then((res) => {
        this.cartItemsLength = res.data.items.cart_total_products_count;
      });
    },
    /**
     * @vuese
     * get favorite Products function
     */
    getWishlistProducts() {
      return globalAxios.get("members/profile/favorite").then((res) => {
        this.wishlistItems = res.data.items.data;
        this.wishlistLength = res.data.items.data.length;
      });
    },
    /**
     * @vuese
     * search function
     */
    search() {
      let data = {
        keyword: this.keyword,
      };
      categories.searchResult(data).then((resp) => {
        if (resp.data.items.suggestions && resp.data.items.suggestions.length) {
          this.suggestionsExist = true;
          this.suggestions = resp.data.items.suggestions;
        } else {
          this.suggestionsExist = false;
        }
      });
    },
    searchSuggestion(word) {
      let r = this.$router.resolve({
        name: "SearchResults", // put your route information in
        query: { keyword: word }, // put your route information in
      });
      window.location.assign(r.href);
    },
    searchBtn() {
      // if (this.keyword.length > 1) {
        let r = this.$router.resolve({
          name: "SearchResults", // put your route information in
          query: { keyword: this.keyword }, // put your route information in
        });
        window.location.assign(r.href);
      // }
    },
    /**
     * @vuese
     * close search function
     */
    closeSearch() {
      this.searchClicked = false;
      this.keyword = "";
    },
    /**
     * @vuese
     * login Now function if not logged in
     */
    loginNow() {
      const loc = document.location;
      if (
        (this.$route.query.force_login &&
          this.$route.query.force_login == "true") ||
        loc.href.includes("force_login")
      ) {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("newUserInfo");
        localStorage.removeItem("buyerUserData");
        if (this.$refs["loginIcon"]) {
          setTimeout(() => {
            this.$refs["loginIcon"].click();
          }, 500);
        }
      }
    },
  },
  computed: {
    loaded() {
      return this.loadingPercent + "%";
    },
    /**
     * @vuese
     * get cart items function
     */
    cartItems() {
      return this.$store.state.cart.cartItems;
    },
    /**
     * @vuese
     * get cart items length function
     */
    cartLength() {
      return this.$store.state.cart.cartLength;
    },
    /**
     * @vuese
     * get notifications function
     */
    notifications() {
      return this.$store.state.notifications;
    },
    /**
     * @vuese
     * get notifications length function
     */
    notificationsLength() {
      return this.$store.state.notificationsLength;
    },
    /**
     * @vuese
     * check logged in or not function
     */
    page() {
      return this.$route.query.force_login == "true";
    },
  },
  mounted() {
    window.onscroll = function () {
      myFunction();
    };
    var header = document.querySelector(".right-side");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky + 300) {
        header.classList.add("fixed-toggle-menu");
      } else {
        header.classList.remove("fixed-toggle-menu");
      }
    }

    this.myInterval = setInterval(() => {
      if (this.loadingPercent == 100) {
        if (
          document.location.href.includes("force_login") &&
          document.location.href.force_login == "true"
        ) {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("newUserInfo");
          localStorage.removeItem("buyerUserData");
          location.reload();
        }
        clearInterval(this.myInterval);
      }
    }, 100);
  },
  destroyed() {
    window.history.pushState({}, document.title, window.location.pathname);
  },
  watch: {
    page() {
      if (this.$route.query.force_login == "true") {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("newUserInfo");
        localStorage.removeItem("buyerUserData");
        if (document.querySelector(".login")) {
          setTimeout(() => {
            document.querySelector(".login").click();
          }, 0);
          setTimeout(() => {
            var newURL = location.href.split("?")[0];
            window.history.pushState("object", document.title, newURL);
          }, 500);
        }
      }
    },
    searchClicked() {
      setTimeout(() => {
        if (this.searchClicked && this.$refs.searchIcon) {
          this.$refs.searchIcon.focus();
        }
      }, 200);
    },
    loadingPercent(val) {
      if (val >= 100) {
        clearInterval(this.interval);
      }
    },
  },
  props: ["siteLogo"],
};
</script>

<style lang="scss" scoped>
/**
    * component style
*/
.main-nav {
  width: 100%;
  transition: 0.5s all ease-in-out;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: $top-header-color;

  nav {
    font-size: 14px;
    padding-top: 12px;
    transition: 0.5s all ease-in-out;
    margin: 0 auto;
    @media (max-width: 767px) {
      padding-top: 0;
    }

    .cart {
      padding: 0 1.5rem;
      position: relative;

      .cart-icon {
        color: #000000;
        font-size: 12pt;
        cursor: pointer;
      }

      .cart-body {
        right: 0;
        background: #fff;
        opacity: 0;
        padding: 1.5rem;
        position: absolute;
        top: 2rem;
        transition: all 0.5s ease 0s;
        z-index: 9;
        box-shadow: 0px 12px 24px 0px rgb(120 120 120 / 30%);
        visibility: hidden;
        transform: translateY(10px);
        width: 27rem;
      }

      &:hover {
        .cart-body {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
          z-index: 9;
        }
      }
    }

    .branding {
      text-align: center;

      img {
        // width: 100px;
        padding: 11px 12px 13px 5px;
        cursor: pointer;
        height: 90px;
        @media (max-width: 992px) {
          height: auto;
        }
      }
    }

    ul li {
      text-transform: uppercase;
      padding: 10px;
      position: relative;

      .link {
        text-decoration: none;
        transition: 0.5s all ease-in-out;
        padding: 0 10px 10px;
        border-bottom: 1px solid transparent;
        color: $text-color;
        font-weight: 700;
        font-size: 16px;

        @media (min-width: 1200px) and (max-width: 1400px) {
          padding: 0 5px 0;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 80%;
          transform: translateY(-50%);
          height: 1px;
          width: 0%;
          margin: 0 auto;
          transition: 0.3s linear;
          background: $main-color;
          width: 0%;
        }
      }

      &:hover {
        .link::before {
          width: 70%;
        }
      }
    }

    .navigation {
      display: flex;
      align-items: center;
    }

    .right-side {
      align-items: center;
      @media (max-width: 767px) {
        display: none;
      }

      .login {
        padding: 0 0 0 15px;
        text-align: center;
      }

      .search-icon {
        .modal-header {
          display: none;
        }

        .icon-search {
          color: #212529;
          background-color: transparent;
          border-color: transparent;

          &:focus {
            box-shadow: 0 0 0 0;
          }
        }
      }
    }
  }
}

html:lang(ar) {
  .main-nav {
    .cart-body,
    .notify-body {
      right: auto;
      left: 0;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  //position: absolute;
  top: 20px;
  height: 100%;
  right: 24px;
  @media (max-width: 500px) {
    top: 60px;
    right: 2rem;
  }

  svg {
    cursor: pointer;
    transition: 0.8s all ease-in-out;
    font-size: 25px;
  }

  .icon-active {
    transform: rotate(180deg);
  }
}

.cartLength {
  background: $main_color;
  color: #fff;
  min-width: 40px;
  min-height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 10px;
  // margin: 0 10px;

  margin: -5px;
  z-index: 5;
  bottom: -11px;
  position: absolute;
}

.cart-number {
  background: $main-color;
}

.search-eye {
  cursor: pointer;
}

.notify-holder {
  transition: all 0.3s ease-in-out;

  &:hover {
    .notifications {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.mobile-search-icon {
  font-size: 20px !important;
}

@media (max-width: 992px) {
  #modal-1 .input-group-text {
    font-size: 25px;
  }

  #modal-1 .form-control {
    font-size: 30px;
    width: 285px;
  }
}

.search-input {
  border-radius: 20px;
  width: 300px;
  transition: all 0.3s ease-in-out;

  @media (max-width: 992px) {
    max-width: 170px;
  }

  @media (min-width: 1200px) and(max-width:1400px) {
    width: 200px;
  }
}

.bar-icon {
  z-index: 1;
}

.form-holder {
  position: relative;

  .search-suggestions {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 300px;
    overflow: scroll;
    background: #fff;
    box-shadow: 0px 12px 24px 0px rgb(120 120 120 / 30%);
    padding: 15px;
    z-index: 99;

    li {
      border-bottom: 1px solid #ccc;
    }
  }

  .meaning-span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
  }
}

.floating-btn {
  button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    outline: none;
    box-shadow: none;
    background: $main-color;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
  }
}

.middle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;

  @media (max-width: 766.98px) {
    // justify-content: inherit;
    justify-content: flex-start;
  }

  @media (min-width: 767px) and (max-width: 11.98px) {
    justify-content: flex-end;
  }
}

.toggleMenu {
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // z-index: 999;
  @media (max-width: 767px) {
    padding: 10px;
  }

  // @media (min-width: 767px) and (max-width: 1200px) {
  //  position: fixed;
  //  top: 2%;
  //   right: 2%;
  // }
}

//.en {
// .toggleMenu {
//   @media (max-width: 766.98px) {
//    top: 2%;
//     right: 0;
//   left: auto;
//    position: fixed;
//    width: 14%;
//  }
// }
//}
//.ar {
//  .toggleMenu {
//  @media (max-width: 766.98px) {
//    top: 2%;
//    left: 2%;
//    right: auto;
//   position: fixed;
//  width: 14%;
//  }
// }
//}

.companies-router {
  border: 2px solid $main-color;
  padding: 6px 15px;
  border-radius: 5px;
  color: $main-color;

  a {
    color: inherit;
    font-weight: 500;
  }
}
.fixed-toggle-menu {
  @media (max-width: 992px) {
    top: 2rem;
    right: 2rem;
    transition: position 1s linear;
  }
}
.main-nav {
  transition: position 0.7s, left 0.7s;
}

.newMobileStyle {
  @media (max-width: 767px) {
    width: 50% !important;
    &:first-of-type {
      order: 2;
      width: 75% !important;
      justify-content: flex-end !important;
    }
    &:nth-of-type(2) {
      order: 1;
      width: 25% !important;
    }
  }
}
.ar {
  .newMobileStyle {
    @media (max-width: 767px) {
      width: 50% !important;
      &:first-of-type {
        width: 75% !important;
        justify-content: flex-end !important;
      }
      &:nth-of-type(2) {
        width: 25% !important;
      }
    }
  }
}
.btn.dropdown-toggle{
  display: flex !important;
  align-items: center !important;
}
</style>
