export default {
  payment: "Payment",
  deliveryData: "Order Contacts Info",
  backToCart: "Back to cart",
  firstName: "First name",
  lastName: "Last name",
  companyName: "Company name",

  optional: "Optional",
  country: "Country",
  address: "Address",
  governorate: "Governorate",
  city: "City",
  postalCode: "Postal code",
  phoneNumber: "Phone number",
  email: "Email",
  deliverySameAddress: "Delivery For The Same Address?",
  paymentData: "Payment Method",
  notes: "Notes",
  total: "Total",
  discount: "Discount",
  freeDelivery: "Free delivery",
  priceUnit: "KWD",
  bankTransfer: "Bank transfer",
  paymentByBank: "Payment by bank",
  // paymentWhenReceiving: "Payment when receiving",
  paymentWhenReceiving: "Cash on Delivery",
  requestReceipt: "Payment with request receipt",
  onlinePayment: "Online payment",
  accept: "I accept",
  termsAndConditions: "Terms and Conditions",
  checkout: "Checkout",
  delivery: "Shipping",
  pickup: "Pick-up",
  selectExist: "Select From Existing",
  country_code: "Country Code",
  chooseCurrency: "Choose Currency",
  waitPurchace1: "Thank You for Your Order!",
  waitPurchace2: "A copy of your order summary has been sent via SMS/EMail",
  congrats: "Congratulations!",
  orderTrack: "Track Your Order",
  fail: "ERROR!",
  fail1: "Something Went Wrong",
  selectOption: "Select Options",
  orderNumber: "Order Number",
  orderDate: "Date",
  paymentMethod: "Payment Method",
  loadingParagraph:
    "Loading payment method, please wait. This may take up to 15 seconds.",
  orderPlaced: "Your Order has been placed",
  checkCachResult:
    "you will be redirected to your order automatically , please wait This may take up to 15 seconds.",
  checkBankResult:
    "you will be redirected to Bank tarnsfer confirmation form , please wait This may take up to 15 seconds.",
  couponValid: "Coupon Is Valid",
  couponNotValid: "Coupon Not Valid",
  uploadImage: "Upload Image",
  paymentStatus: "Payment Status",
  bankImageDownload: "View Transfer File",
  bankTransImage: "Bank transfer image",
  bankComment: "Bank transfer notes",
  orderStatus: "Order Status",
  cancelOrder: "Cancel",
  postalError: "Must Be 6 numbers",
  CountryRequired: "Country is Mandatory",
  RegionRequired: "Region is Mandatory",
  CityRequired: "City is Mandatory",
  AddressRequired: "Address is Mandatory",
  choosePaymentMethod: "Choose Your Payment Method",
  haveCoupon: "got a coupon code?",
  Apply: "Apply",
  search: "Search",
  coupon: "Coupon",
  BoubyanOnlinePayment: "Boubyan Online Payment",
  boubyanOnlinePayment: "Boubyan Online Payment",
  CardNumber: "Card Number",
  ExpiryMonth: "Expiry Month",
  ExpiryYear: "Expiry Year",
};
