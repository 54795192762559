<template>
  <div>
    <!-- buyer register page  -->
    <!-- buyer form component page  -->
    <B2bForm />
  </div>
</template>
<script>
import B2bForm from "@/components/pages/B2bForm.vue";
import auth from "@/services/auth";
import apiControls from "@/services/apiControls";
export default {
  components: {
    B2bForm,
  },
  async mounted() {
    await apiControls.checkApiControls().then((res) => {
      let locale_b2b_buyer_registration = res.data.items.find(
        (el) => el.api_name == "b2b_buyer_registration"
      );
      if (Number(locale_b2b_buyer_registration?.status) !== 1) {
        this.locale_b2b_buyer_registration = false;
      } else {
        this.locale_b2b_buyer_registration = true;
      }
    });

    await auth.getGuestSettings().then((res) => {
      let locale_b2b_status = res.data.items.find(
        (el) => el.key == "b2b_status"
      );
      if (Number(locale_b2b_status?.status) !== 1) {
        this.locale_b2b_status = false;
      } else {
        this.locale_b2b_status = true;
      }
    });
    if (this.locale_b2b_buyer_registration !== true || this.locale_b2b_status !== true) {
      this.$router.push("/");
    }
  },
  metaInfo: {
    title: "Register Page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  data() {
    return {
      locale_b2b_status: true,
      locale_b2b_buyer_registration: true,
    };
  },
};
</script>
