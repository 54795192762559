var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-info"},[(_vm.myProduct)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[(_vm.myProduct.product.title)?_c('h4',{staticClass:"name m-0 p-0"},[_vm._v(" "+_vm._s(_vm.myProduct.product.title)+" ")]):_c('span',{staticClass:"is-available text-danger"},[_c('b',[_vm._v(_vm._s(_vm.$t("singleProduct.outOfStock")))])])]),(_vm.lastCategory)?_c('div',{staticClass:"categoryNme"},[(_vm.lastCategory.id)?_c('router-link',{attrs:{"to":("/categories/" + (_vm.lastCategory.id) + "/variants")}},[(_vm.lastCategory.title)?_c('p',{staticClass:"name m-0 p-0"},[_c('ins',[_vm._v(_vm._s(_vm.lastCategory.title))])]):_vm._e()]):_vm._e()],1):_vm._e()]),(
            _vm.myProduct.product_details_by_type.add_type !== 'rfq' &&
            _vm.myProduct.product_details_by_type.add_type !== 'visit_website'
          )?_c('p',{staticClass:"price mt-4"},[(
              _vm.myProduct.product_details_by_type
                .currency_based_price_before_discount &&
              _vm.myProduct.product_details_by_type
                .currency_based_price_before_discount >
                _vm.myProduct.product_details_by_type
                  .currency_based_customer_price && _vm.myProduct.product_details_by_type.currency_based_price_before_discount > 0
            )?_c('span',{staticClass:"price-after description ml-0"},[_vm._v(" "+_vm._s(_vm._f("fixedCurrency")(_vm.myProduct.product_details_by_type .currency_based_price_before_discount))+" "+_vm._s(_vm.currency)+" ")]):_vm._e(),(_vm.myProduct.product_details_by_type.currency_based_customer_price > 0)?_c('span',{staticClass:"price-before"},[_vm._v(" "+_vm._s(_vm._f("fixedCurrency")(_vm.myProduct.product_details_by_type.currency_based_customer_price))+" "+_vm._s(_vm.currency)+" ")]):_vm._e()]):_vm._e(),(_vm.myProduct.product_details_by_type)?_c('div',{staticClass:"mt-4"},[(_vm.myProduct.product_details_by_type.sku)?_c('p',{staticClass:"serial"},[_vm._v(" SKU : "),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.myProduct.product_details_by_type.sku))])]):_vm._e(),(
              _vm.myProduct.product_details_by_type.unit &&
              _vm.myProduct.product_details_by_type.weight
            )?_c('div',{staticClass:"weight mb-0"},[(_vm.myProduct.product_details_by_type.weight)?_c('span',{staticClass:"title mr-3 mb-2"},[_vm._v(" "+_vm._s(_vm.$t("items.unit"))+" : ")]):_vm._e(),_c('span',[(_vm.myProduct.product_details_by_type)?_c('div',{staticClass:"available-weight d-flex justify-content-end"},[(_vm.myProduct.product_details_by_type.unit)?_c('span',[_vm._v(_vm._s(_vm.myProduct.product_details_by_type.weight)+" "+_vm._s(_vm.myProduct.product_details_by_type.unit.title))]):_vm._e()]):_vm._e()])]):_vm._e()]):_vm._e(),(_vm.myProduct.brand)?_c('div',{staticClass:"serial text-black mt-0"},[_c('span',[_c('p',[_vm._v(_vm._s(_vm.$t("profile.countryOrigin"))+" :")])]),_c('span',{staticClass:"mx-3 mt-2"},[_vm._v(" "+_vm._s(_vm.myProduct.country.title)+" ")]),(_vm.myProduct.country_of_origin)?_c('span',[_vm._v(_vm._s(_vm.myProduct.country_of_origin))]):_vm._e()]):_vm._e(),_c('ProductDescription',{staticClass:"my-4 mb-5",attrs:{"myProduct":_vm.myProduct}}),_c('div',{staticClass:"my-4"},[(_vm.myProduct.dynamic_infos_by_form.length)?_c('div',[_c('ProductDynamicComponent',{attrs:{"dynamicInputs":_vm.myProduct.dynamic_infos_by_form}})],1):_vm._e()]),(_vm.myProduct.variants)?_c('div',{staticClass:"variants"},_vm._l((_vm.myProduct.variants),function(myVariant){return _c('div',{key:myVariant.id,staticClass:"sort"},[(myVariant.variant.title)?_c('b',[_vm._v(" "+_vm._s(myVariant.variant.title)+" ")]):_vm._e(),(
                myVariant.variant &&
                myVariant.variant.options &&
                myVariant.variant.options.length
              )?_c('div',{staticClass:"weight options-holder"},_vm._l((myVariant.variant.options),function(option){return _c('div',{key:option.id,staticClass:"available-weight"},[(option.title && option.id == myVariant.option_id)?_c('span',{staticClass:"active"},[(option.id == myVariant.option_id)?_c('span',{staticClass:"text-white p-2"},[_vm._v(" "+_vm._s(option.title)+" ")]):_vm._e()]):_vm._e()])}),0):_vm._e()])}),0):_vm._e()],1),_c('div',{staticClass:"col-12"},[_c('ProductActions',{attrs:{"myProduct":_vm.myProduct}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }