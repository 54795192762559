<template>
  <div>
    <!-- register page for buyer   -->
    <!-- register component for buyer   -->
    <RegisterForm />
  </div>
</template>
<script>
import RegisterForm from "@/components/pages/RegisterForm.vue";
import auth from "@/services/auth";
export default {
  components: {
    RegisterForm,
  },
  metaInfo: {
    title: "buyer register page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  mounted() {
    auth.getGuestSettings().then((res) => {
      let locale_b2c_status = res.data.items.find(el => el.key == 'b2c_status')
      if (Number(locale_b2c_status?.status) !== 1) {
        this.$router.push("/");
      }
    });
  },
  data(){
    return {
      locale_b2c_status:true
    }
  }
};
</script>
