<template>
  <div>
    <!-- Hidden Invoice Content -->
    <div id="invoice-container" ref="invoice" class="hidden-invoice">
      <!-- Header -->
      <div class="header" v-if="buyerUserData">
        <h1 style="text-align: center; font-size: 18px; margin-bottom: 20px">
          {{ $t("profile.packageDetails") }}
        </h1>
      </div>

      <!-- Subscription Details -->
      <div
        v-if="buyerUserData && buyerUserData.active_subscription"
        class="hold-all-data"
      >
        <div class="invoice-header my-3 d-flex jusify-content-center text-center">
          <img
            :src="logoEnv || siteLogo"
            class="invoice-logo"
            alt="site_logo"
          />
        </div>

        <div class="row">
          <div class="col-6">
            <strong>{{ $t("profile.userName") }}:</strong>
          </div>
          <div class="col-6">
            <p>
              {{ buyerUserData.first_name }}
              {{ buyerUserData.last_name }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong>{{ $t("profile.package") }}:</strong>
          </div>
          <div class="col-6">
            <p>
              <span v-if="$i18n.locale === 'en'" class="mx-0 px-0">{{
                buyerUserData.active_subscription.subscription.name_en
              }}</span>
              <span v-else class="mx-0 px-0">{{
                buyerUserData.active_subscription.subscription.name_ar
              }}</span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong>{{ $t("profile.description") }}:</strong>
          </div>
          <div class="col-6">
            <p>
              {{
                $i18n.locale === "en"
                  ? buyerUserData.active_subscription.subscription.name_en
                  : buyerUserData.active_subscription.subscription.name_ar
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong>{{ $t("items.price") }}:</strong>
          </div>
          <div class="col-6">
            <p class="d-flex plan-price">
              <span>
                {{ buyerUserData.active_subscription.subscription.cost || "0" }}
                <span v-if="$i18n.locale === 'en'" class="mx-0 px-0">USD</span>
                <span v-else class="mx-0 px-0">دولار</span>
              </span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong>{{ $t("items.startedAt") }}:</strong>
          </div>
          <div class="col-6">
            <p>
              {{ buyerUserData.active_subscription.start_at }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong>{{ $t("items.endAt") }}:</strong>
          </div>
          <div class="col-6">
            <p>
              {{ buyerUserData.active_subscription.end_at }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  name: "InvoiceComponent",
  props: {
    mode: {
      type: String,
      default: "preview",
    },
  },
  methods: {
    downloadInvoice() {
      const invoiceElement = this.$refs.invoice;

      // Temporarily show the hidden content
      invoiceElement.style.display = "block";

      // Configure html2pdf options
      const options = {
        margin: [10, 10, 10, 10], // Margins in mm
        filename: "invoice.pdf", // Filename
        image: { type: "jpeg", quality: 0.98 }, // Image settings
        html2canvas: { scale: 2, useCORS: true }, // Ensure proper scaling and cross-origin support
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }, // PDF format
      };

      // Generate and download the PDF
      html2pdf()
        .set(options)
        .from(invoiceElement)
        .save()
        .then(() => {
          // Hide the content again after PDF generation
          invoiceElement.style.display = "none";
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
#invoice-container {
  display: none; /* Completely hide content from the screen */
  width: 190mm; /* Fit within A4 width */
  min-height: 270mm; /* A4 height with margins */
  padding: 20px;
  background-color: white;
  font-family: Arial, sans-serif;
  font-size: 14px; /* Fixed font size for consistency */
  line-height: 1.6; /* Add line spacing to prevent overlap */
}

.header {
  text-align: center;
}

.row {
  display: flex;
  margin-bottom: 15px; /* Add space between rows */
}

.col-6 {
  flex: 1; /* Flexbox column */
  padding-right: 10px; /* Space between columns */
}

strong {
  word-break: break-word; /* Prevent long text from overflowing */
  word-spacing: 3px;
  letter-spacing: 5px;
}

p {
  margin: 0; /* Reset margin for better alignment */
  letter-spacing: 5px;
}

.hold-all-data {
  margin-top: 20px;
}

.plan-price {
  letter-spacing: 2px;
}
.invoice-logo {
  width: 150px;      /* Set desired width */
  height: 80px;      /* Set desired height */
  object-fit: contain;
}

</style>
